import {
  trackSegmentEvent,
  getContextProperties,
} from '~/components/shared/tracking/segmentAnalytics';
import { moveFacetedFilters } from '~/components/shared/moveFacetedFilters.js';

// this needs to be here to update the header when the filters are applied
const directoryHeroHeader = document.querySelector(
  '[data-directory-new-header-title]',
);

document
  .querySelectorAll('[data-controller="faceted-filter-form"]')
  .forEach((facetedFilterFormEl) => {
    facetedFilterFormEl.addEventListener('submit', async (e) => {
      e.preventDefault();

      const fireSegmentEvent = () => {
        const properties = {
          action: 'apply filter',
        };
        const propertiesToDelete = {};
        const inputTargets = Array.from(
          facetedFilterFormEl.querySelectorAll('input'),
        );
        inputTargets.forEach((input) => {
          const inputSegmentDataAttr = input.dataset.segmentPropName;
          if (!inputSegmentDataAttr) {
            return;
          }

          const segment_name = inputSegmentDataAttr
            .replace('data-segment-', '')
            .replace('-', '_');

          if (!input.checked) {
            propertiesToDelete[segment_name] = true;
            return;
          }

          if (properties.hasOwnProperty(segment_name)) {
            properties[segment_name] += `, ${input.value}`;
          } else {
            properties[segment_name] = input.value;
          }
        });

        for (const key of Object.keys(propertiesToDelete)) {
          if (typeof properties[key] === 'undefined') {
            properties[key] = undefined;
          }
        }
        trackSegmentEvent('Directory - Results', {
          ...getContextProperties(),
          ...properties,
        });
      };

      fireSegmentEvent();
      facetedFilterFormEl.submit();
    });
  });

document
  .querySelectorAll('[data-controller="faceted-filter"]')
  .forEach((controllerElement) => {
    // These are necessary until we actually have stimulus set up to take care of this for us
    const activatorTextValue =
      controllerElement.dataset.facetedFilterActivatorTextValue;
    const filterPanelShowClass =
      controllerElement.dataset.facetedFilterPanelShowClass;
    const activatorActiveClass =
      controllerElement.dataset.facetedFilterActivatorActiveClass;
    const activatorWithSelectionClass =
      controllerElement.dataset.facetedFilterActivatorWithSelectionClass;
    const generalNav = document.querySelector('[data-general-nav]');
    const activatorTarget = controllerElement.querySelector(
      '[data-faceted-filter-target="activator"]',
    );
    const panelTarget = controllerElement.querySelector(
      '[data-faceted-filter-target="panel"]',
    );
    const singleValueButton = controllerElement.querySelector(
      '[data-single-value]',
    );

    const activatorTextElement =
      activatorTarget.querySelector('.activator__text');

    const inputTargets = Array.from(
      controllerElement.querySelectorAll(
        '[data-faceted-filter-target="input"]',
      ),
    );

    function getOverlayElement() {
      let overlay = document.querySelector('.generic-modal__overlay');
      if (overlay) return overlay;
      overlay = document.createElement('DIV');
      overlay.classList.add('generic-modal__overlay');
      document.body.prepend(overlay);
      return overlay;
    }

    function uncheckSameNameWithDifferentValue() {
      const inputName = inputTargets[0].name;
      const inputs = document.querySelectorAll(`input[name="${inputName}"]`);
      inputs.forEach((input) => {
        if (input.value !== inputTargets[0].value) {
          input.checked = false;
        }
      });
    }

    function setSingleValueCheckbox() {
      if (!singleValueButton) return;

      inputTargets[0].checked = !inputTargets[0].checked;

      if (inputTargets[0].checked === false) {
        uncheckSameName();
      }

      if (inputTargets[0].checked === true) {
        uncheckSameNameWithDifferentValue();
      }
    }

    function singleValueIsChecked() {
      if (!singleValueButton) return false;
      return inputTargets[0].checked;
    }

    function getCheckedOptions() {
      return inputTargets.filter((input) => input.checked);
    }

    function getActivatorTextFormLabel(label) {
      if (!label) return activatorTextElement.innerHTML;

      return label.dataset.displayText || label.textContent;
    }

    function setCurrentSelections(checkedOptions) {
      if (checkedOptions.length === 0) return;

      const values = [];

      checkedOptions.forEach((input) => {
        values.push(input.id);
      });
      controllerElement.dataset.facetedFilterCurrentFilterValue =
        values.join(',');
    }

    function getCurrentFilterValues() {
      return controllerElement.dataset.facetedFilterCurrentFilterValue;
    }

    function clearCurrentFilterValues() {
      // this is to make sure all the single action buttons are cleared
      document.querySelectorAll('[data-single-value]').forEach((el) => {
        el.dataset.facetedFilterCurrentFilterValue = '';
        el.classList.remove('faceted-filter__activator--with-selection');
        el.dataset.facetedFilterActivatorWithSelectionClass = '';
        const checkboxes = Array.from(
          el.querySelectorAll('[data-faceted-filter-target="input"]'),
        );
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      });
      controllerElement.dataset.facetedFilterCurrentFilterValue = '';
    }

    function filtersClearedValue() {
      return controllerElement.dataset.facetedFilterFiltersClearedValue;
    }

    function filtersCleared() {
      return filtersClearedValue() === 'true';
    }

    function setFiltersClearedValue(bool) {
      controllerElement.dataset.facetedFilterFiltersClearedValue =
        bool.toString();
    }

    function revert() {
      uncheckAllOptions();
      revertSelections();
    }

    function updatePageHeader() {
      if (
        singleValueIsChecked() &&
        directoryHeroHeader.innerHTML.includes(' in ')
      ) {
        const activatorText = activatorTextElement.innerHTML;
        const city = directoryHeroHeader.innerHTML
          .split('in ')[1]
          .split('<')[0];
        // this is necessary because the method can be called twice (if multiple single value filters are active, which makes the city value blank)
        if (city) {
          switch (activatorText) {
            case 'Open Now':
              directoryHeroHeader.innerHTML = `Book same-day urgent care open now in <strong>${city}</strong>`;
              break;
            case 'Accepts Kids':
              directoryHeroHeader.innerHTML = `Book same-day urgent care that accepts kids in <strong>${city}</strong>`;
              break;
            case 'Telemedicine':
              directoryHeroHeader.innerHTML = `Book same-day telemedicine in <strong>${city}</strong>`;
              break;
            default:
              return;
          }
        }
      }
    }

    function newOptionsSelected() {
      let checkedInputs = getCheckedOptions();

      if (checkedInputs.length > 0) {
        checkedInputs = checkedInputs.map((input) => input.id);
      }

      const currentFilterSelection = getCurrentFilterValues();

      return checkedInputs.join(',') !== currentFilterSelection;
    }

    function shouldRevert() {
      const currentFilters = getCurrentFilterValues();
      return (
        currentFilters.length > 0 &&
        newOptionsSelected() &&
        filtersCleared() === false
      );
    }

    function uncheckAllOptions() {
      getCheckedOptions().forEach((input) => (input.checked = false));
    }

    function revertSelections() {
      const currentValues = getCurrentFilterValues().split(',');
      const targetInputs = inputTargets.filter((input) =>
        currentValues.includes(input.id),
      );
      targetInputs.forEach((input) => (input.checked = true));
    }

    function updateActivatorState() {
      const checkedInputs = getCheckedOptions();
      const checkedCount = checkedInputs.length;

      if (checkedCount === 0) {
        activatorTarget.classList.remove(activatorWithSelectionClass);
        activatorTextElement.innerHTML = activatorTextValue;
      } else {
        if (shouldRevert()) {
          revert();
          return;
        }
        activatorTarget.classList.add(activatorWithSelectionClass);
        const firstChecked = checkedInputs[0];
        let activatorTextHTML = getActivatorTextFormLabel(
          firstChecked.labels[0],
        );

        const additionalCount = checkedCount - 1;

        if (additionalCount > 0) {
          activatorTextHTML += ` <strong>+${additionalCount}</strong>`;
        }
        activatorTextElement.innerHTML = activatorTextHTML;
      }
    }

    function uncheckSameName() {
      const inputName = inputTargets[0].name;
      const inputs = document.querySelectorAll(`input[name="${inputName}"]`);
      inputs.forEach((input) => (input.checked = false));
    }

    let opened = false;

    function close(e) {
      document.removeEventListener('click', onClickOutside);

      if (e && e.target.type === 'submit') {
        return;
      }

      activatorTarget.classList.remove(activatorActiveClass);
      if (generalNav) {
        generalNav.classList.remove('faceted-panel-open');
      }

      // this is a fix for ios, as it doesn't fire scroll event when you scroll to the top
      if (
        window.pageYOffset <= 0 ||
        (window.pageYOffset <= 0 && window.innerHeight < 356)
      ) {
        if (generalNav) {
          generalNav.classList.remove('is-sticking');
        }
        moveFacetedFilters(generalNav);
      }

      panelTarget.classList.remove(filterPanelShowClass);
      if (window.innerWidth < 768) {
        document.body.classList.remove('no-scroll');
        getOverlayElement().classList.remove('opened');
      }
      opened = !opened;
      window.facetedFilterOpen = false;
    }

    const onClickOutside = (e) => {
      e.stopPropagation();
      const isOutside =
        !controllerElement.contains(e.target) && e.target !== controllerElement;
      if (isOutside) {
        close();
        document.removeEventListener('click', onClickOutside);
      }
    };

    function open() {
      document.addEventListener('click', onClickOutside);
      if (generalNav) {
        generalNav.classList.add('faceted-panel-open');
        generalNav.classList.add('is-sticking');
      }
      panelTarget.classList.add(filterPanelShowClass);
      activatorTarget.classList.add(activatorActiveClass);
      if (window.innerWidth < 768) {
        document.body.classList.add('no-scroll');
        getOverlayElement().classList.add('opened');
        moveFacetedFilters(generalNav);
      }
      opened = !opened;
      window.facetedFilterOpen = true;
    }

    function deselectOther(e) {
      inputTargets.forEach((input) => {
        if (e.target !== input) {
          input.checked = false;
        }
      });
    }

    function singleSelect(e) {
      if (singleValueButton) {
        setSingleValueCheckbox();
        updateActivatorState();
      }
    }

    const actions = {
      deselectOther,
      close,
      toggle: async () => {
        if (opened) {
          close();
        } else {
          if (activatorTarget.disabled === true) return;
          if (window.facetedFilterOpen) return;
          open();
        }
      },
      clear: () => {
        inputTargets.forEach((input) => {
          input.checked = false;
        });
        setFiltersClearedValue(true);
        clearCurrentFilterValues();
      },
      singleSelect: () => {
        singleSelect();
      },
    };

    controllerElement.querySelectorAll('[data-action]').forEach((el) => {
      const actionName = el.dataset.action.split('#')[1];

      if (actionName === 'deselectOther') {
        el.addEventListener('change', actions[actionName]);
      } else {
        el.addEventListener('click', actions[actionName]);
      }
    });

    setCurrentSelections(getCheckedOptions());
    updateActivatorState();
    updatePageHeader();
  });
